import { FormControl,HStack,FormLabel,NumberInput,NumberInputField,Input,Select, Heading, Center, VStack, CardBody, Card, Button, Table, Thead, Tr, Th, Td, Tbody, 
    useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, Icon, CircularProgress } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import telegram from "../MainSite/Telegram";
import { CustomToast } from "../MainSite/Toast";
import { FaDatabase, FaRegEdit, FaSave } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";

export default function EditStudentInvoiceButton(props){
    //declaring hooks
    const navigate = useNavigate();
    const [houseList, setHouseList] = useState([]);
    const [prodList, setProdList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    var headerData = JSON.parse(props.studentInvSearchData.headers);
    headerData=headerData[0] //header data from search invoice
    var productData  = JSON.parse(props.studentInvSearchData.products); //product data from search invoice
    
    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(process.env.REACT_APP_API+`/db_house_search?school_id=${headerData.school_id}`);
          const newData = await response.json();
          setHouseList(newData);
          const response1 = await fetch(process.env.REACT_APP_API+`/db_product_search?school_id=${headerData.school_id}`);
          const newData1 = await response1.json();
          var x = JSON.parse(newData1) //converting the result of API call to JSON
          setProdList(x); //setting up th entire product list from API call
        };
        fetchData();
      },[headerData.school_id]);
    
    var date = new Date(headerData['date_of_purchase'])     //convert the unix date from backend to the correct format i.e yyyy-mm-dd
    const formattedDate = `${date.getFullYear()}-${((date.getMonth() + 1) < 10 ? '0':'')+(date.getMonth() + 1)}-${(date.getDate() < 10 ? '0':'')+date.getDate()}`;
      
    const [disabled, setDisabled] = useState(true); //for disabling the input fields on button click
    const [finalProductList, setFinalProductList] = useState({}); // for storing the final prod list to be sent for persisting in db
    var [headerState, setHeaderState] = useState({
        userID: headerData.user_id,
        schoolID: headerData.school_id,
        student_name: headerData.student_name,
        class: headerData.class,
        roll_no: headerData.roll_no,
        house_name: headerData.house_name,
        date_of_purchase: formattedDate,
        bill_no:headerData.bill_no,
        total_price:0,
        total_items:0,            
        });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    
    //JS functions
    const MakeItem = function(X) { // to make the dropdown for houses
        return <option>{X}</option>;
    };
    const onChangeInput = (e, index) => {
        const { name, value } = e.target
        prodList[index][name]=value;
        prodList[index]['total_price']=prodList[index]['product_price']*prodList[index]['item_quantity']
    }
    
    function handleSubmit(){
        if (disabled === true) 
            setDisabled(false);
        else
            setHeaderState({...headerState, bill_no: 'PWPL/'+props.schoolData.schoolCode+'/'+new Date().getFullYear()+'/'+(new Date(headerState.date_of_purchase).getMonth()+1)+'/'+headerState.roll_no})
        
    }
    
    const {addToast} = CustomToast();
    
    const handleChanges = async event =>{
        setIsLoading(true);
        var price = 0;
        var items = 0;
        prodList.map((value) => value['total_price'] ?  price+=parseInt(value['total_price']): price);
        prodList.map((value) => value['item_quantity'] ? items+=parseInt(value['item_quantity']): items);
        setHeaderState({...headerState, total_items:items, total_price:price, bill_no: 'PWPL/'+props.schoolData.schoolCode+'/'+new Date().getFullYear()+'/'+(new Date(headerState.date_of_purchase).getMonth()+1)+'/'+headerState.roll_no})
        

        for (let x in prodList){    
            if(prodList[x].hasOwnProperty('item_quantity') === true && prodList[x]['item_quantity'] !== "")
                finalProductList[x]=prodList[x];
            else
                continue;
        }
        const a = await (await fetch(process.env.REACT_APP_API+`/db_delete_student_invoice?inv_no=${props.studentInvSearchCriteria.invNo}&date_of_purchase=${props.studentInvSearchCriteria.invDate}`)).json();
        const persistData = async () => {
            const response= await fetch(process.env.REACT_APP_API+"/db_save_student_invoice", {
            
            // Adding method type
            method: "POST",
            
            // Adding body or contents to send
            body: JSON.stringify({
                header: headerState,
                products: finalProductList,
            }),
            
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
                
        // Converting to JSON
        const newData = await response.json();
        console.log('stat', newData.status)
        // Capturing the status from backend
        if (newData.status === 200){ // check for the status
            addToast({title: 'Invoice Edited', description: 'Changes Saved Successfully', status: 'success'}) //calling toast
            const message=`Invoice# ${headerState['bill_no']} Edited Successfully. All Changes Saved to Database. Kindly reconfirm for any mistakes.`
            telegram(message)
        }else
            addToast({title: 'Failed', description: 'Unable to save changes', status: 'error'})

        // Navigating to view Invoice Page
        navigate('/home')

        }
        if (a.status === 200)
            await persistData(); //calling the persist data function
        onClose();
        setIsLoading(false);
    }
    
    const goBack = () => {
        onClose();
        setIsLoading(false);
        setFinalProductList({});
    }

    return(
        
        <Center mb={20}>
            <VStack>
                <HStack mt={5} w={'100%'} spacing={'63%'}>
                    <Heading ml={5} as={'h3'} size={'lg'}><Icon boxSize={6} as={FaRegEdit}/>&nbsp; Edit Student Invoice </Heading>
                    
                    {disabled ? <Button onClick={handleSubmit} colorScheme='telegram' variant='solid' leftIcon={<FaRegEdit/>}>Edit Invoice</Button> : 
                     <Button onClick={onOpen} colorScheme='facebook' variant='solid' leftIcon={<FaSave/>}>Save Changes</Button>}
                        <AlertDialog
                        motionPreset='slideInBottom'
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                        isOpen={isOpen}
                        isCentered
                        >
                        <AlertDialogOverlay />
                
                        <AlertDialogContent>
                            <AlertDialogHeader>Confirm Changes?</AlertDialogHeader>
                            <AlertDialogCloseButton />
                            <AlertDialogBody>
                            Are you sure you want to save the changes to this Invoice. This action cannot be reversed.
                            </AlertDialogBody>
                            <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={goBack} colorScheme="pink" leftIcon={<IoMdArrowBack/>} isDisabled={isLoading}>
                                Go Back
                            </Button>
                            <Button colorScheme='whatsapp' ml={3} onClick={handleChanges} leftIcon={<FaDatabase/>} isDisabled={isLoading}>
                                {isLoading? <CircularProgress isIndeterminate size="24px" color="teal" /> : 'Proceed' }
                            </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                        </AlertDialog>
                </HStack>
                <Card>
                    <CardBody>
                    <FormControl isRequired>
                        <HStack m={2} spacing={5}>
                        <HStack>
                            <FormLabel fontSize='xs'>Name</FormLabel>
                            <Input defaultValue={headerData['student_name']} isDisabled={disabled}  type='text'  name='student_name' onChange={(e)=> setHeaderState({ ...headerState,student_name:e.target.value })}/>
                        </HStack>
                        
                        <HStack>
                            <FormLabel fontSize='xs' >Class</FormLabel>
                            <NumberInput name="class" isDisabled={disabled} defaultValue={headerData['class']}>
                                <NumberInputField onChange={(e)=> setHeaderState({ ...headerState,class:e.target.value })}/>
                            </NumberInput>
                        </HStack>

                        <HStack>
                            <FormLabel fontSize='xs' >Roll No.</FormLabel>
                            <NumberInput name="roll_no" isDisabled={disabled} defaultValue={headerData['roll_no']}>
                                <NumberInputField onChange={(e)=> setHeaderState({ ...headerState,roll_no:e.target.value })}/>
                            </NumberInput>
                        </HStack>
                       
                        <HStack>
                            <FormLabel w={'10em'} fontSize='xs'>Invoice Date</FormLabel>
                            <Input defaultValue={headerState.date_of_purchase} isDisabled={disabled} type='date' name='date_of_purchase' onChange={(e)=> setHeaderState({ ...headerState,date_of_purchase:e.target.value })}/>    
                        </HStack> 
                        
                        <HStack>
                            <FormLabel fontSize='xs'>House</FormLabel>
                            <Select isDisabled={disabled} placeholder={headerData['house_name']} size='md' name="house_name" onChange={(e)=> setHeaderState({ ...headerState,house_name:e.target.value })}>
                                {houseList.map(MakeItem)}                
                            </Select>
                        </HStack>
                        </HStack>  
                    </FormControl>
                    </CardBody>
                </Card>
                
                <Table mb={20}>
            <Thead>
            <Tr>
                <Th>Sr.</Th>
                <Th>Item</Th>
                <Th>Rate</Th>
                <Th>Size</Th>
                <Th>Quantity</Th>
            </Tr>
            </Thead>
            <Tbody>
                
                {
                    prodList.map((value,index) => { 
                        var qty ="";
                        var size="";
                        for (let x of productData){
                            
                            if (x['product_name'] === value['product_name']){
                                qty = x['item_quantity'];
                                size = x['size'];
                                value['item_quantity'] = x['item_quantity'];
                                value['size'] = x['size'];
                                value['total_price'] = x['item_quantity'] * value['product_price']
                                break;
                            }
                            else
                                continue;
                }
                        
                            
                        return(
                            <Tr key={index}>
                                <Th>{index+1}</Th>
                                <Th>{value['product_name']}</Th>
                                <Th>&#8377;&nbsp;{parseInt(value['product_price']).toLocaleString('en-IN')}</Th>
                                <Td>
                                    <NumberInput isDisabled={disabled} defaultValue={size}>
                                        <NumberInputField name="size" onChange={(e)=> onChangeInput(e, index)}/>
                                    </NumberInput>
                                </Td>
                                <Td>
                                    <NumberInput isDisabled={disabled} defaultValue={qty}>
                                        <NumberInputField name= "item_quantity" onChange={(e)=> onChangeInput(e, index)}/>
                                    </NumberInput>
                                </Td>                                
                            </Tr>
                        )
                    })    
                }
            </Tbody>
          </Table>
        </VStack>
    </Center>
        
    )
}