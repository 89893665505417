export default async function Activate(){

        const response = await(await fetch(process.env.REACT_APP_API+'/check')).json()
        if (response['status'] === 200)
            console.log('DB Conn Success')
        const response1 = await(await fetch(process.env.REACT_APP_API+'/check')).json()
        if (response1['status'] === 200)
            console.log('Tel Success')
                      
        
    
}